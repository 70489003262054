import https from "./https"
import store from "@/store"

const organization = store.state.general.organization;

const preorder = {
  /** 預購單設定檔 */
  getPreorderSettings(params) {
    return https.get(`admin/organizations/${organization.id}/preorder-settings`, { params });
  },
  createPreorderSetting(params) {
    return https.post(`admin/organizations/${organization.id}/preorder-settings`, params);
  },
  getPreorderSetting(id) {
    return https.get(`admin/organizations/${organization.id}/preorder-settings/${id}`);
  },
  updatePreorderSetting(id, params) {
    return https.put(`admin/organizations/${organization.id}/preorder-settings/${id}`, params);
  },
  deletePreorderSetting(id) {
    return https.delete(`admin/organizations/${organization.id}/preorder-settings/${id}`);
  },
  getSelectableProducts(params) {
    return https.get(`admin/organizations/${organization.id}/preorder-settings/selectable-products`, { params });
  },
  getPreorderSettingProducts(id, params) {
    return https.get(`admin/organizations/${organization.id}/preorder-settings/${id}/products`, { params });
  },
  /** 預購單 */
  getPreorderList(id, params) {
    return https.get(`admin/organizations/${organization.id}/preorder-settings/${id}/preorders`, { params });
  },
  getPreorder(id) {
    return https.get(`admin/organizations/${organization.id}/preorders/${id}`);
  },
  deletePreorder(id) {
    return https.delete(`admin/organizations/${organization.id}/preorders/${id}`);
  },
  updatePreorder(id, params) {
    return https.put(`admin/organizations/${organization.id}/preorders/${id}`, params);
  },
}

export default preorder
